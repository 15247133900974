import 'controllers/application_controller'
import 'controllers/address_controller'

import Vue from 'vue/dist/vue.esm'
import VueAxios from 'vue-axios'
import BootstrapVue from 'bootstrap-vue'
import { Plugin } from 'vue-fragment'

import axiosConfig from 'config/axios'
import i18n from 'config/i18n-setup'

Vue.use(BootstrapVue)
Vue.use(VueAxios, axiosConfig)
Vue.use(Plugin)

import 'bootstrap-vue/dist/bootstrap-vue.css'

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap")

window.Sticky = require("sticky-js")
window.Cookies = require("js-cookie")
window.PerfectScrollbar = require("perfect-scrollbar/dist/perfect-scrollbar")

window.KTAppOptions = {
  "colors": {
    "state": {
      "brand": "#5d78ff",
      "dark": "#282a3c",
      "light": "#ffffff",
      "primary": "#5867dd",
      "success": "#34bfa3",
      "info": "#36a3f7",
      "warning": "#ffb822",
      "danger": "#fd3995"
    },
    "base": {
      "label": [
        "#c5cbe3",
        "#a1a8c3",
        "#3d4465",
        "#3e4466"
      ],
      "shape": [
        "#f0f3ff",
        "#d9dffa",
        "#afb4d4",
        "#646c9a"
      ]
    }
  }
}


// Keentheme"s plugins
window.KTUtil = require("../template/global/components/base/util")
window.KTApp = require("../template/global/components/base/app")
window.KTAvatar = require("../template/global/components/base/avatar")
window.KTDialog = require("../template/global/components/base/dialog")
window.KTHeader = require("../template/global/components/base/header")
window.KTMenu = require("../template/global/components/base/menu")
window.KTOffcanvas = require("../template/global/components/base/offcanvas")
window.KTPortlet = require("../template/global/components/base/portlet")
window.KTScrolltop = require("../template/global/components/base/scrolltop")
window.KTToggle = require("../template/global/components/base/toggle")
window.KTWizard = require("../template/global/components/base/wizard")
require("../template/global/components/base/datatable/core.datatable")
require("../template/global/components/base/datatable/datatable.checkbox")
require("../template/global/components/base/datatable/datatable.rtl")

// Layout scripts
window.KTLayout = require("../template/global/layout/layout")
window.KTChat = require("../template/global/layout/chat")
require("../template/global/layout/demo-panel")
require("../template/global/layout/offcanvas-panel")
require("../template/global/layout/quick-panel")
require("../template/global/layout/quick-search")

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    i18n,
    el: '#kt_content',
    components: {
      'addresses-collection': () => import('app/addresses/collection'),
      'report-collection': () => import('app/report/collection'),
      'bids-collection': () => import('app/bids/collection'),
      'profiles-collection': () => import('app/profiles/collection'),
      'processing-collection': () => import('app/processing/collection'),
      'regionals-collection': () => import('app/regionals/collection'),
      'users-collection': () => import('app/users/collection'),
      'bids-input-bidding-date': () => import('app/bids/InputDate'),
      'processing-edit-map': () => import('app/processing/editMap')
    }
  })
})
