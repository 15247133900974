import Vue from 'vue/dist/vue.esm'
import VueI18n from 'vue-i18n'

import locale from './locale.js'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'pt-BR',
  fallbackLocale: 'pt-BR',
  messages: locale
})

export default i18n
