import axios from 'axios'

const instance = axios.create({
  headers: { 'X-CSRF-Token': $('[name="csrf-token"]').attr('content')},
  baseURL: window.location.origin
})
instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

export default instance
